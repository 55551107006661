import { useTranslation } from '@/components';
import {
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from '@/components/pdf-table';
import { fontWeights, getTextColor, lightenHexColor } from '@/helpers';
import { useTheme } from '@mui/material';
import { Text } from '@react-pdf/renderer';
import { ItemProgress, StyledTable } from '@survey/report/pdf';

interface ReportItemRowProps {
  reports: TableDetailedItemResponse[];
  relation: TopicRelationDetailedResponse;
  options: TopicOptionResponse[];
  hasBenchmark: boolean;
  rowIndex: number;
  lastRow: boolean;
}

const ReportItemRow = ({
  reports,
  relation,
  options,
  hasBenchmark,
  rowIndex,
  lastRow
}: ReportItemRowProps) => {
  reports = reports.filter((x) => x.relationId === relation.id);

  const counTableRowesponds = (
    reports: TableDetailedItemResponse[],
    option: TopicOptionResponse
  ) => {
    const count = reports.count((x) => x.option.value === option.value);
    if (count === 0) {
      return '';
    }

    return count.toString();
  };

  return (
    <TableRow rowIndex={rowIndex} lastRow={lastRow}>
      <TableCell isHeader firstColumn>
        {relation.relation}
      </TableCell>
      {options.map((x, i) => (
        <TableCell key={i}>{counTableRowesponds(reports, x)}</TableCell>
      ))}
      <TableCell lastColumn={!hasBenchmark}>
        {reports
          .filter((x) => !x.option.optional)
          .average((x) => x.option.value! * (x.relation?.impact || 1))
          .toFixedString(1, '-')}
      </TableCell>
      {hasBenchmark && (
        <>
          <TableCell>{reports[0]?.benchmark || '-'}</TableCell>
          <TableCell style={{ padding: '4px 8px' }} lastColumn>
            <ItemProgress
              user={reports
                .filter((x) => !x.option.optional)
                .average((x) => x.option.value! * (x.relation?.impact || 1))}
              other={reports[0]?.benchmark}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

interface IProps {
  id: string;
  options: TopicOptionResponse[];
  rows: TableDetailedItemResponse[];
  relations?: TopicRelationResponse[];
  tableQuestionAverageDisabled?: boolean;
}

const TableDetailed = ({
  id,
  options,
  rows,
  relations,
  tableQuestionAverageDisabled
}: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');
  const hasBenchmark = rows.any((x) => !!x.benchmark && x.benchmark !== 0);

  const total = (rows: TableDetailedItemResponse[]) => {
    return rows
      .filter((x) => !x.option.optional && !x.owner)
      .average((x) => x.option.value! * (x.relation?.impact || 1))
      .toFixedString(1, '-');
  };

  const totalBenchmark = (rows: TableDetailedItemResponse[]) => {
    return rows
      .filter((x) => !x.option.optional && !x.owner)
      .groupBy((x) => ({
        relationId: x.relationId,
        relation: x.relation
      }))
      .map((x) => x.items.average((y) => y.benchmark || 0))
      .average((x) => x)
      .toFixedString(1, '-');
  };

  return (
    <StyledTable>
      <TableHead>
        <TableCell isHeader firstColumn empty />
        {options.map((y) => (
          <TableCell key={`item_${id}_option_${y.id}_title`} isHeader>
            {y.title}
          </TableCell>
        ))}
        <TableCell isHeader lastColumn={!hasBenchmark}>
          {t('Average')}
        </TableCell>
        {hasBenchmark && (
          <>
            <TableCell isHeader>{t('CohortBenchmark')}</TableCell>
            <TableCell isHeader lastColumn>
              {t('YoursVSOthers')}
            </TableCell>
          </>
        )}
      </TableHead>
      <TableBody>
        <TableRow rowIndex={0}>
          <TableCell isHeader firstColumn>
            {t('SelfRating')}
          </TableCell>
          {options.map((x) => (
            <TableCell key={`item_${id}_self_option_${x.id}_value`}>
              {rows.any(
                (y) => y.owner === true && y.option.value === x.value
              ) && 'OK'}
            </TableCell>
          ))}
          <TableCell lastColumn={!hasBenchmark}>
            {rows
              .filter((x) => x.owner && !x.option.optional)
              .average((x) => x.option.value!)
              .toFixedString(1, '-')}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell>
                {rows
                  .filter((x) => x.owner)
                  .average((x) => x.benchmark || 0)
                  .toFixedString(1, '-')}
              </TableCell>
              <TableCell style={{ padding: '4px 8px' }} lastColumn>
                <ItemProgress
                  user={rows
                    .filter((x) => x.owner && !x.option.optional)
                    .average((x) => x.option.value!)}
                  other={rows
                    .filter((x) => x.owner)
                    .average((x) => x.benchmark || 0)}
                />
              </TableCell>
            </>
          )}
        </TableRow>
        {relations && relations.any() ? (
          relations.map((x, i) => (
            <ReportItemRow
              key={`item_${id}_relation_${x.id}`}
              reports={rows.filter((y) => !y.owner)}
              relation={x}
              options={options}
              hasBenchmark={hasBenchmark}
              rowIndex={i + 1}
              lastRow={
                i === relations.length - 1 &&
                (tableQuestionAverageDisabled || false)
              }
            />
          ))
        ) : (
          <ReportItemRow
            key={`item_${id}_relation_other`}
            reports={rows.filter((y) => !y.owner)}
            relation={{
              id: 'others',
              impact: 1,
              minResponses: 0,
              relation: 'Others'
            }}
            options={options}
            hasBenchmark={hasBenchmark}
            rowIndex={1}
            lastRow={tableQuestionAverageDisabled || false}
          />
        )}
      </TableBody>
      {(!tableQuestionAverageDisabled && (
        <TableFooter>
          <TableCell />
          <TableCell
            weight={options.length + 1}
            style={{
              textAlign: 'center',
              backgroundColor: lightenHexColor(
                theme.palette.secondary.main,
                80
              ),
              padding: 6,
              color: getTextColor(
                lightenHexColor(theme.palette.secondary.main, 80)
              )
            }}
          >
            <Text
              style={{
                fontWeight: fontWeights.semiBold
              }}
            >
              {t('QuestionAverage')}
            </Text>
            <Text
              style={{
                fontWeight: fontWeights.light,
                fontSize: 10
              }}
            >
              ({t('ExcludingSelfRating')})
            </Text>
          </TableCell>
          <TableCell lastColumn={!hasBenchmark}>{total(rows)}</TableCell>
          {hasBenchmark && (
            <>
              <TableCell>{totalBenchmark(rows)}</TableCell>
              <TableCell lastColumn />
            </>
          )}
        </TableFooter>
      )) || <></>}
    </StyledTable>
  );
};

export default TableDetailed;
