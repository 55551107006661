import { Loading } from '@/components';
import AINotif from '@/components/ai-notif';
import CustomizedThemeProvider from '@/components/customized-theme-provider';
import {
  languageChangeSubscribe,
  languageChangeUnsubscribe
} from '@/components/multi-lang';
import ReportContext from '@/contexts/report-context';
import { getQueryString, useQueryString, webClient } from '@/helpers';
import { report } from '@/helpers/testdata';
import ErrorComponent from '@/pages/error';
import { useAppContext } from '@contexts/app-context';
import { PDFViewer } from '@react-pdf/renderer';
import SurveyReportPdf from '@survey/report/survey-report-pdf';
import SurveyReportTable from '@survey/report/survey-report-table';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';

const SurveyReport = () => {
  const { setError, ...context } = useAppContext();
  const id = useParams<'id'>().id;
  const { isPdf } = useQueryString<'isPdf'>();

  const [data, setData] = useState<SimpleReportResponse>();
  const [hideAINotif, setHideAINotif] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      return;
    }

    function loadReport() {
      setLoading(true);
      if (getQueryString('test') === 'test') {
        setData(report.data as any);
        setLoading(false);
        return;
      }

      webClient.api.reports
        .survey(id!, {
          loadingHandled: true
        })
        .then((res) => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }

    loadReport();

    const languageSubscriptionId = languageChangeSubscribe(loadReport);

    return () => languageChangeUnsubscribe(languageSubscriptionId);

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return (
      <ErrorComponent
        code='404'
        context={{
          setError
        }}
      />
    );
  }

  const render = () => (
    <>
      <Helmet>
        <style>{`body { background-image: none !important }`}</style>
      </Helmet>

      {(isPdf === 'true' && (
        <PDFViewer height={800}>
          <SurveyReportPdf {...data} />
        </PDFViewer>
      )) || (
        <ReportContext.Provider
          value={{
            ...context,
            setError,
            ...data
          }}
        >
          <>
            <SurveyReportTable />
            {!hideAINotif && <AINotif onClose={() => setHideAINotif(true)} />}
          </>
        </ReportContext.Provider>
      )}
    </>
  );

  if (
    data.customizations &&
    (data.customizations.primaryColor || data.customizations.secondaryColor)
  ) {
    return (
      <CustomizedThemeProvider customizations={data.customizations}>
        {render()}
      </CustomizedThemeProvider>
    );
  }

  return render();
};

export default SurveyReport;
