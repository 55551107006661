import { PdfTable } from '@/components/pdf-table';
import { View } from '@react-pdf/renderer';
import { ReactElement } from 'react';

interface IProps {
  children: ReactElement[];
  columnsWeights?: number[];
}

const StyledTable = ({ children }: IProps) => {
  return <PdfTable>{children}</PdfTable>;
};

const StyledTableContainer = (props: IProps) => (
  <View
    style={{
      marginTop: 4,
      marginBottom: 1,
      paddingBottom: 1
    }}
  >
    <StyledTable {...props}>{props.children}</StyledTable>
  </View>
);

export default StyledTableContainer;
