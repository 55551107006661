import { lightenHexColor } from '@/helpers';
import { useTheme } from '@mui/material';
import { Text, View } from '@react-pdf/renderer';
import { createStyleSheet } from '@survey/report/pdf';

type IProps = SurveyRespondComment & {
  relation?: TopicRelationResponse;
  index: number;
};

const CommentBox = ({ index, comment, relation }: IProps) => {
  const theme = useTheme();

  const styleSheet = createStyleSheet({
    wrapper: {
      fontSize: 14,
      borderRadius: 24,
      backgroundColor: lightenHexColor('#000', 99),
      padding: '32px 32px 16px',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 16
    },
    content: {
      fontStyle: 'italic',
      fontWeight: 'extralight'
    },
    relations: {
      alignSelf: 'flex-end',
      fontWeight: 'semibold',
      color: theme.palette.primary.main,
      lineHeight: 16
    }
  });

  return (
    <View style={styleSheet.wrapper} break={false} key={`comment_${index}`}>
      <Text style={styleSheet.content}>{comment}</Text>
      {relation && (
        <Text style={styleSheet.relations}>
          {relation?.relation || 'Friend'}
        </Text>
      )}
    </View>
  );
};

export default CommentBox;
