import { LocalStorageKeys, TopicThemes } from '@/enums';
import { ignore } from '@/helpers';
import SurveyShare from '@/pages/survey/share';
import SurveyRelationship from '@survey/relation-ship';
import SurveyThanks from '@survey/thanks';
import LocalstorageComponent from '@test/localstorage';
import { Route, Routes } from 'react-router';

export default function SurveyRouter() {
  return (
    <Routes>
      <Route
        path='/relation-ship'
        element={
          <SurveyRelationship
            next={ignore}
            nickName='Test'
            familiarity={{
              optional: false,
              title: 'How familiar are you with {name}?',
              options: [
                {
                  id: '2',
                  title: 'Colleague',
                  value: 2
                },
                {
                  id: '3',
                  title: 'Family',
                  value: 3
                },
                {
                  id: '4',
                  title: 'Acquaintance',
                  value: 4
                }
              ]
            }}
            relations={[
              {
                id: '2',
                relation: 'Colleague',
                impact: 1,
                minResponses: 2
              },
              {
                id: '3',
                relation: 'Family',
                impact: 1,
                minResponses: 2
              },
              {
                id: '4',
                relation: 'Friend',
                impact: 1,
                minResponses: 2
              }
            ]}
            skipFamiliarity={false}
            skipRelations={false}
            theme={TopicThemes.IMPACT}
          />
        }
      />
      <Route
        path='/share'
        element={
          <LocalstorageComponent
            name={LocalStorageKeys.SURVEY_DATA}
            value={JSON.stringify({
              theme: TopicThemes.IMPACT,
              systemManageDisabled: false,
              url: 'https://test.com/test',
              relations: [
                {
                  id: '1',
                  impact: 1,
                  minResponses: 0,
                  relation: 'Friends'
                }
              ]
            } as SurveyStartResponse)}
          >
            <SurveyShare />
          </LocalstorageComponent>
        }
      />
      <Route
        path='/share/self'
        element={
          <LocalstorageComponent
            name={LocalStorageKeys.SURVEY_DATA}
            value={JSON.stringify({
              selfAsses: true,
              theme: TopicThemes.IMPACT
            } as SurveyStartResponse)}
          >
            <SurveyShare />
          </LocalstorageComponent>
        }
      />
      <Route
        path='/thanks'
        element={
          <LocalstorageComponent
            name={LocalStorageKeys.SURVEY_THANKS_DATA}
            value={JSON.stringify({
              topic: 'test',
              customizations: {},
              theme: TopicThemes.IMPACT
            })}
          >
            <SurveyThanks />
          </LocalstorageComponent>
        }
      />
    </Routes>
  );
}
