import { useTheme } from '@mui/material';
import { View } from '@react-pdf/renderer';

interface IProps {
  color?: 'success';
  value: number;
}

const ProgressBar = ({ color, value }: IProps) => {
  const theme = useTheme();
  const col = color || 'primary';

  return (
    <View
      style={{
        position: 'relative',
        height: 10,
        backgroundColor: theme.palette[col].light
      }}
    >
      <View
        style={{
          position: 'absolute',
          left: 0,
          height: 10,
          width: value,
          backgroundColor: theme.palette[col].main
        }}
      />
    </View>
  );
};

export default ProgressBar;
