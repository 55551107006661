import { useTranslation } from '@/components';
import {
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from '@/components/pdf-table';
import { fontWeights, getTextColor, lightenHexColor } from '@/helpers';
import { useTheme } from '@mui/material';
import { Text } from '@react-pdf/renderer';
import { ItemProgress, StyledTable } from '@survey/report/pdf';

interface ReportItemRowProps {
  rows: TableFamiliarityItemResponse[];
  familiarity: TopicFamiliarityOptionResponse;
  options: TopicOptionResponse[];
  hasBenchmark: boolean;
  rowIndex: number;
}

const ReportItemRow = ({
  rows,
  familiarity,
  options,
  hasBenchmark,
  rowIndex
}: ReportItemRowProps) => {
  rows = rows.filter((x) => x.familiarityId === familiarity.id);

  const counTableRowesponds = (
    rows: TableFamiliarityItemResponse[],
    option: TopicOptionResponse
  ) => {
    const count = rows.count((x) => x.option.value === option.value);
    if (count === 0) {
      return '';
    }

    return count.toString();
  };

  return (
    <TableRow rowIndex={rowIndex}>
      <TableCell isHeader weight={5} firstColumn>
        {familiarity.title}
      </TableCell>
      {options.map((x, i) => (
        <TableCell key={i} center>
          {counTableRowesponds(rows, x)}
        </TableCell>
      ))}
      <TableCell center lastColumn={!hasBenchmark}>
        {rows
          .filter((x) => !x.option.optional)
          .average((x) => x.option.value!)
          .toFixedString(1, '-')}
      </TableCell>
      {hasBenchmark && (
        <>
          <TableCell center>{rows[0]?.benchmark || '-'}</TableCell>
          <TableCell style={{ padding: '4px 8px' }} lastColumn>
            <ItemProgress
              user={rows
                .filter((x) => !x.option.optional)
                .average((x) => x.option.value!)}
              other={rows[0]?.benchmark}
            />
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

interface IProps {
  id: string;
  options: TopicOptionResponse[];
  rows: TableFamiliarityItemResponse[];
  familiarity: TopicFamiliarityResponse;
  tableQuestionAverageDisabled?: boolean;
}

const TableFamiliarity = ({
  id,
  options,
  rows,
  familiarity,
  tableQuestionAverageDisabled
}: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');
  const hasBenchmark = rows.any((x) => !!x.benchmark && x.benchmark !== 0);

  const total = (rows: TableFamiliarityItemResponse[]) => {
    return rows
      .filter((x) => !x.option.optional && !x.owner)
      .average((x) => x.option.value!)
      .toFixedString(1, '-');
  };

  const totalBenchmark = (rows: TableFamiliarityItemResponse[]) => {
    return rows
      .filter((x) => !x.option.optional && !x.owner)
      .groupBy((x) => ({
        familiarityId: x.familiarityId,
        familiarity: x.familiarity
      }))
      .map((x) => x.items.average((y) => y.benchmark || 0))
      .average((x) => x)
      .toFixedString(1, '-');
  };

  return (
    <StyledTable>
      <TableHead>
        <TableCell isHeader firstColumn weight={5} empty />
        {options.map((y) => (
          <TableCell key={`${id}_option_${y.id}_title`} isHeader>
            {y.title}
          </TableCell>
        ))}
        <TableCell isHeader lastColumn={!hasBenchmark}>
          {t('Average')}
        </TableCell>
        {hasBenchmark && (
          <>
            <TableCell isHeader>{t('CohortBenchmark')}</TableCell>
            <TableCell lastColumn isHeader>
              {t('YoursVSOthers')}
            </TableCell>
          </>
        )}
      </TableHead>
      <TableBody>
        <TableRow rowIndex={0}>
          <TableCell isHeader firstColumn weight={5}>
            {t('SelfRating')}
          </TableCell>
          {options.map((x) => (
            <TableCell key={`${id}_self_option_${x.id}_value`} center>
              {rows.any(
                (y) => y.owner === true && y.option.value === x.value
              ) && 'OK'}
            </TableCell>
          ))}
          <TableCell lastColumn={!hasBenchmark} center>
            {rows
              .filter((x) => x.owner && !x.option.optional)
              .average((x) => x.option.value!)
              .toFixedString(1, '-')}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell center>
                {rows
                  .filter((x) => x.owner)
                  .average((x) => x.benchmark || 0)
                  .toFixedString(1, '-')}
              </TableCell>
              <TableCell style={{ padding: '4px 8px' }} lastColumn>
                <ItemProgress
                  user={rows
                    .filter((x) => x.owner && !x.option.optional)
                    .average((x) => x.option.value!)}
                  other={rows
                    .filter((x) => x.owner)
                    .average((x) => x.benchmark || 0)}
                />
              </TableCell>
            </>
          )}
        </TableRow>
        {familiarity &&
          familiarity.options.map((x, i) => (
            <ReportItemRow
              rowIndex={i + 1}
              key={`${id}_relation_${x.id}`}
              rows={rows.filter((y) => !y.owner)}
              familiarity={x}
              options={options}
              hasBenchmark={hasBenchmark}
            />
          ))}
      </TableBody>
      {(!tableQuestionAverageDisabled && (
        <TableFooter>
          <TableCell weight={5} firstColumn empty />
          <TableCell
            weight={options.length + 1}
            style={{
              textAlign: 'center',
              backgroundColor: lightenHexColor(
                theme.palette.secondary.main,
                80
              ),
              padding: 6,
              color: getTextColor(
                lightenHexColor(theme.palette.secondary.main, 80)
              )
            }}
          >
            <Text
              style={{
                fontWeight: fontWeights.semiBold
              }}
            >
              {t('QuestionAverage')}
            </Text>
            <Text
              style={{
                fontWeight: fontWeights.light,
                fontSize: 10
              }}
            >
              ({t('ExcludingSelfRating')})
            </Text>
          </TableCell>
          <TableCell center lastColumn={!hasBenchmark}>
            {total(rows)}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell center>{totalBenchmark(rows)}</TableCell>
              <TableCell lastColumn />
            </>
          )}
        </TableFooter>
      )) || <></>}
    </StyledTable>
  );
};

export default TableFamiliarity;
