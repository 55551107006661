import { useTranslation } from '@/components';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@/components/pdf-table';
import { getByKey } from '@/helpers';
import { StyledTable } from '@survey/report/pdf';

interface IProps {
  id: string;
  rows: TableOverallFamiliarityItemResponse[];
  familiarity: TopicFamiliarityResponse;
  items: TopicItemResponse[];
}

const TableOverallFamiliarity = ({ id, rows, familiarity, items }: IProps) => {
  const t = useTranslation('Reports.Table');
  const hasBenchmark = rows.any((x) => !!x.benchmark && x.benchmark !== 0);

  return (
    <StyledTable>
      <TableHead>
        <TableCell isHeader weight={hasBenchmark ? 10 : 11} empty firstColumn />
        <TableCell isHeader>{t('SelfRating')}</TableCell>
        {familiarity.options.map((x, i) => (
          <TableCell
            key={`header_${x.id}`}
            isHeader
            lastColumn={i === familiarity.options.length - 1 && !hasBenchmark}
          >
            {x.title}
          </TableCell>
        ))}
        {hasBenchmark && (
          <TableCell isHeader weight={3} lastColumn>
            {t('CohortBenchmark')}
          </TableCell>
        )}
      </TableHead>
      <TableBody>
        {items.map((x, i) => {
          const data = rows.find((y) => y.itemId === x.id);
          return (
            <TableRow
              key={`${id}_row${i}`}
              rowIndex={i}
              lastRow={i === items.length - 1}
            >
              <TableCell isHeader firstColumn weight={hasBenchmark ? 10 : 11}>
                {x.name}
              </TableCell>
              <TableCell isHeader center>
                {data?.selfRating || 'NA'}
              </TableCell>
              {familiarity.options.map((y, j) => (
                <TableCell
                  key={`${id}_col_${j}`}
                  isHeader
                  center
                  lastColumn={
                    j === familiarity.options.length - 1 && !hasBenchmark
                  }
                >
                  {getByKey(data?.familiarityRatings, y.id)?.toFixedString(
                    1,
                    'NA'
                  )}
                </TableCell>
              ))}
              {hasBenchmark && (
                <TableCell isHeader weight={3} lastColumn>
                  {rows
                    .filter((x) => x.owner)
                    .average((x) => x.benchmark || 0)
                    .toFixedString(1, 'NA')}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default TableOverallFamiliarity;
