import { TopicItemTableType, TopicTableType } from '@/enums';
import {
  TableAnonymous,
  TableAverage,
  TableDetailed,
  TableFamiliarity,
  TableOverall,
  TableOverallFamiliarity
} from '@survey/report/pdf';

interface IProps {
  id: string;
  table: TableResponseType;
  options?: TopicOptionResponse[];
  relations?: TopicRelationResponse[];
  familiarity?: TopicFamiliarityResponse;
  items?: TopicItemResponse[];
  tableQuestionAverageDisabled?: boolean;
}

const TableRenderer = ({
  id,
  table,
  items,
  options,
  relations,
  familiarity,
  tableQuestionAverageDisabled
}: IProps) => {
  if (table.type === TopicItemTableType.DETAILED) {
    return (
      <TableDetailed
        key={`${id}_detailedTable`}
        id={`${id}_detailedTable`}
        options={options!}
        relations={relations}
        rows={table.items}
        tableQuestionAverageDisabled={tableQuestionAverageDisabled}
      />
    );
  }

  if (table.type === TopicItemTableType.AVERAGE) {
    return (
      <TableAverage
        key={`${id}_averageTable`}
        id={`${id}_averageTable`}
        relations={relations}
        rows={table.items}
        tableQuestionAverageDisabled={tableQuestionAverageDisabled}
      />
    );
  }

  if (table.type === TopicItemTableType.ANONYMOUS) {
    return (
      <TableAnonymous
        key={`${id}_anonymousTable`}
        id={`${id}_anonymousTable`}
        options={options!}
        rows={table.items}
        tableQuestionAverageDisabled={tableQuestionAverageDisabled}
      />
    );
  }

  if (table.type === TopicItemTableType.FAMILIARITY) {
    return (
      <TableFamiliarity
        key={`${id}_familiarityTable`}
        id={`${id}_familiarityTable`}
        options={options!}
        rows={table.items}
        familiarity={familiarity!}
        tableQuestionAverageDisabled={tableQuestionAverageDisabled}
      />
    );
  }

  if (table.type === TopicTableType.OVERALL) {
    return (
      <TableOverall
        key={`${id}_overallTable`}
        id={`${id}_overallTable`}
        items={items!}
        rows={table.items}
      />
    );
  }

  if (table.type === TopicTableType.OVERALL_FAMILIARITY) {
    return (
      <TableOverallFamiliarity
        key={`${id}_overallFamiliarityTable`}
        id={`${id}_overallFamiliarityTable`}
        items={items!}
        rows={table.items}
        familiarity={familiarity!}
      />
    );
  }

  return <></>;
};

export default TableRenderer;
