import { createContext, useContext } from 'react';

const TableContext = createContext<ITableContext>({} as any);
const useTableContext = () => useContext(TableContext);

const RowContext = createContext<IRowContext>({} as any);
const useRowContext = () => useContext(RowContext);

export { RowContext, TableContext, useRowContext, useTableContext };

