import { ReactElement } from 'react';

interface IProps {
  name: string;
  value: string;
  children: ReactElement;
}

export default function LocalstorageComponent({
  name,
  value,
  children
}: IProps) {
  localStorage.setItem(name, value);
  return children;
}
