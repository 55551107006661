import { useTranslation } from '@/components';
import { usePdfContext } from '@/contexts/pdf-context';
import { fontWeights, getCdnUrl } from '@/helpers';
import { useTheme } from '@mui/material';
import { Image, Text, View } from '@react-pdf/renderer';
import { A5Page, ChapterIcon, createStyleSheet } from '@survey/report/pdf';
import { ReactNode } from 'react';

type IProps = {
  index?: string;
  name: string;
  image?: string;
  section?: string;
  icon?: ReactNode;
  single?: boolean;
};

const ChapterCover = ({
  index,
  name,
  image,
  section,
  icon,
  single
}: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');
  const {} = usePdfContext();

  const styleSheet = createStyleSheet({
    page: {
      display: 'flex',
      flexDirection: 'row'
    },
    text_area: {
      position: 'relative',
      flexBasis: 'auto',
      flexGrow: 1,
      flexShrink: 1
    },
    icon: {
      width: 20,
      position: 'absolute',
      left: '10%',
      top: '6.5cm'
    },
    section: {
      marginLeft: 6,
      fontSize: 18,
      fontWeight: fontWeights.bold
    },
    separator: {
      position: 'absolute',
      top: '7.2cm',
      left: '10%',
      right: 0,
      height: 1.4,
      backgroundColor: theme.palette.primary.main
    },
    index: {
      position: 'absolute',
      top: '7.4cm',
      left: '10%',
      fontSize: 12,
      fontWeight: fontWeights.extraLight
    },
    name: {
      position: 'absolute',
      top: '8cm',
      left: '10%',
      fontSize: 14,
      fontWeight: fontWeights.bold,
      color: theme.palette.secondary.main
    }
  });

  return (
    <A5Page style={styleSheet.page} wrap={false} break={false}>
      <View style={styleSheet.text_area}>
        {icon || <ChapterIcon style={styleSheet.icon} />}
        {section && <Text style={styleSheet.section}>{section}</Text>}
        <View style={styleSheet.separator} />
        {index !== undefined && <Text style={styleSheet.index}>{index}</Text>}
        <Text style={styleSheet.name}>
          {single ? t('YourReportBasedOnAnalysis') : name}
        </Text>
      </View>
      <Image
        src={image || getCdnUrl('/images/reports/chapter.png')}
        style={{
          height: '14.8cm'
        }}
      />
    </A5Page>
  );
};

export default ChapterCover;
