import { PdfMarkdown } from '@/components/markdown';
import { fontWeights, getCdnUrl } from '@/helpers';
import { useTheme } from '@mui/material';
import { Image, Text, View } from '@react-pdf/renderer';

interface IProps {
  title?: string;
  content: string;
  charts?: ChartResponse[];
}

const AIReport = ({ title, content, charts }: IProps) => {
  const theme = useTheme();

  return (
    <View
      style={{
        minHeight: charts && charts.any() ? '600px' : undefined
      }}
    >
      {title && (
        <Text
          style={{
            color: theme.palette.primary.main,
            fontSize: 18,
            fontWeight: fontWeights.bold,
            marginBottom: 16 / 12
          }}
        >
          {title}
        </Text>
      )}
      {/* {charts && charts.any() && (
        <Box
          sx={{
            '&>div': {
              maxWidth: '450px',
              width: '450px',
              height: '450px',
              float: 'right',
              zIndex: 2,
              borderRadius: '24px',
              overflow: 'hidden',
              border: `solid 1px ${addAlphaToHex(
                theme.palette.primary.main,
                50
              )}`,
              boxShadow: `0 4px 4px ${addAlphaToHex('#000', 25)}`,
              [theme.breakpoints.down('md')]: {
                float: 'none',
                maxWidth: '100%',
                width: '100%',
                mb: 2
              }
            }
          }}
        >
          <LazyPlotly data={charts[0].data} layout={charts[0].layout} />
        </Box>
      )} */}
      <View
        style={{
          position: 'relative'
        }}
      >
        <Image
          source={getCdnUrl('/images/reports/ai-report-bg.svg')}
          style={{
            position: 'absolute'
          }}
        />
        <View
          style={{
            fontSize: 16
          }}
        >
          <PdfMarkdown>{content.clear()}</PdfMarkdown>
        </View>
      </View>
    </View>
  );
};

export default AIReport;
