import {
  RowContext,
  TableContext,
  useRowContext,
  useTableContext
} from '@/contexts/table-context';
import { getTextColor, lightenHexColor } from '@/helpers';
import { useTheme } from '@mui/material';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import React, { ReactNode } from 'react';

const createStyleSheet = <T extends Record<string, Style>>(
  styleSheet: T
): T => {
  const keys = Object.keys(styleSheet);
  const defaults: Style = {
    fontFamily: 'Inter'
  };

  for (const key of keys) {
    styleSheet[key as keyof T] = {
      ...defaults,
      ...styleSheet[key as keyof T]
    };
  }

  return StyleSheet.create(styleSheet);
};

const styles = createStyleSheet({
  table: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10,
    border: 1,
    padding: 4,
    borderRadius: '12px'
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCell: {
    flex: 1,
    borderColor: '#FFF',
    padding: 4,
    fontSize: 10,
    justifyContent: 'center'
  },
  emptyCell: {
    flex: 1,
    borderColor: '#FFF',
    padding: 4
  },
  tableHeader: {
    fontWeight: 'bold'
  }
});

type IStyledTable<T = {}> = T & {
  style?: Style;
};

type IStyledTableWithChildren<T = {}> = IStyledTable<
  T & {
    children: ReactNode | ReactNode[];
  }
>;

type ITableCellProps = IStyledTable<{
  children?: string | ReactNode;
  isHeader?: boolean;
  weight?: number | string;
  center?: boolean;
  empty?: boolean;
  firstColumn?: boolean;
  lastColumn?: boolean;
}>;

export const TableCell = ({
  children,
  isHeader,
  style,
  weight,
  center,
  empty,
  firstColumn,
  lastColumn
}: ITableCellProps) => {
  const theme = useTheme();
  const row = useRowContext();
  const { position } = useTableContext();

  let allStyles: Style[] = [styles.tableCell];
  let emptyStyles: Style[] = [styles.emptyCell];

  if (style) {
    allStyles = [...allStyles, style];
    emptyStyles = [...emptyStyles, style];
  }

  if (weight) {
    allStyles = [
      ...allStyles,
      {
        flex: weight
      }
    ];
    emptyStyles = [
      ...emptyStyles,
      {
        flex: weight
      }
    ];
  }

  if (center) {
    allStyles = [
      ...allStyles,
      {
        alignItems: 'center'
      }
    ];
  }

  if (!lastColumn) {
    allStyles = [
      ...allStyles,
      {
        borderRightWidth: 4
      }
    ];

    emptyStyles = [
      ...emptyStyles,
      {
        borderRightWidth: 4
      }
    ];
  }

  if (empty) {
    return <View style={emptyStyles} />;
  }

  if (isHeader) {
    allStyles = [...allStyles, styles.tableHeader];
  }

  if (position === 'thead') {
    allStyles = [
      ...allStyles,
      {
        backgroundColor: lightenHexColor(theme.palette.secondary.main, 85),
        color: getTextColor(lightenHexColor(theme.palette.secondary.main, 85))
      }
    ];

    if (lastColumn) {
      allStyles = [
        ...allStyles,
        {
          borderTopRightRadius: '10px'
        }
      ];
    }
  }

  if (typeof row.rowIndex === 'number') {
    if (row.rowIndex % 2 === 0) {
      allStyles = [
        ...allStyles,
        {
          backgroundColor: lightenHexColor(theme.palette.secondary.main, 85),
          color: getTextColor(lightenHexColor(theme.palette.secondary.main, 85))
        }
      ];
    } else {
      allStyles = [
        ...allStyles,
        {
          backgroundColor: lightenHexColor(theme.palette.secondary.main, 95),
          color: getTextColor(lightenHexColor(theme.palette.secondary.main, 95))
        }
      ];
    }
  }

  if (!row.lastRow) {
    allStyles = [
      ...allStyles,
      {
        borderBottomWidth: 4
      }
    ];
  }

  if (row.lastRow) {
    if (firstColumn) {
      allStyles = [
        ...allStyles,
        {
          borderBottomLeftRadius: '10px'
        }
      ];
    } else if (lastColumn) {
      allStyles = [
        ...allStyles,
        {
          borderBottomRightRadius: '10px'
        }
      ];
    }
  }

  const childrenType = typeof children;

  return (
    <View style={allStyles}>
      {['string', 'number'].contains(childrenType) ? (
        <Text>{children}</Text>
      ) : (
        children
      )}
    </View>
  );
};

export const TableRow = ({
  children,
  style,
  rowIndex,
  lastRow
}: IStyledTableWithChildren<{
  rowIndex?: number;
  lastRow?: boolean;
}>) => {
  let allStyles: Style[] = [styles.tableRow];
  if (style) {
    allStyles = [...allStyles, style];
  }

  return (
    <RowContext.Provider
      value={{
        firstRow: rowIndex === 0,
        lastRow: !!lastRow,
        rowIndex
      }}
    >
      <View style={allStyles}>{children}</View>
    </RowContext.Provider>
  );
};

export const TableHead = ({ children, style }: IStyledTableWithChildren) => {
  let allStyles: Style | Style[] = [styles.tableRow, styles.tableHeader];
  if (style) {
    allStyles = [...allStyles, style];
  }

  return (
    <TableContext.Provider
      value={{
        position: 'thead'
      }}
    >
      <View style={allStyles}>{children}</View>
    </TableContext.Provider>
  );
};

export const TableBody = ({ children }: IStyledTableWithChildren) => {
  return (
    <TableContext.Provider
      value={{
        position: 'tbody'
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const TableFooter = ({ children, style }: IStyledTableWithChildren) => {
  let allStyles: Style | Style[] = [styles.tableRow];
  if (style) {
    allStyles = [...allStyles, style];
  }

  return (
    <TableContext.Provider
      value={{
        position: 'tfoot'
      }}
    >
      <RowContext.Provider
        value={{
          lastRow: true,
          firstRow: false
        }}
      >
        <View style={allStyles}>{children}</View>
      </RowContext.Provider>
    </TableContext.Provider>
  );
};

export const PdfTable = ({ children, style }: IStyledTableWithChildren) => {
  const theme = useTheme();
  let allStyles: Style | Style[] = [
    styles.table,
    {
      borderColor: lightenHexColor(theme.palette.secondary.main, 50)
    }
  ];

  if (style) {
    allStyles = [...allStyles, style];
  }

  return <View style={allStyles}>{children}</View>;
};
