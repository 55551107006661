import { Box, Typography } from '@mui/material';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { createStyleSheet } from '@survey/report/pdf';
import MarkdownToJsx, { MarkdownToJSX } from 'markdown-to-jsx';
import { Link } from 'react-router-dom';

const pdfTextRules = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '15',
  '16',
  '17',
  '18',
  '19',
  '21',
  '22',
  '24',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32'
];

interface IProps {
  children: string | undefined;
  options?: MarkdownToJSX.Options;
}

const defaultOptions: MarkdownToJSX.Options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        variant: 'h1',
        mb: 1,
        fontSize: {
          xs: '18px',
          sm: '20px'
        }
      }
    },
    h2: {
      component: Typography,
      props: {
        variant: 'h2',
        mb: 1,
        fontSize: {
          xs: '16px',
          sm: '18px'
        }
      }
    },
    h3: {
      component: Typography,
      props: {
        variant: 'h3',
        mb: 1
      }
    },
    h4: {
      component: Typography,
      props: {
        variant: 'h4',
        mb: 1
      }
    },
    h5: {
      component: Typography,
      props: {
        variant: 'h5',
        mb: 1
      }
    },
    h6: {
      component: Typography,
      props: {
        variant: 'h6',
        mb: 1
      }
    },
    p: {
      component: Typography,
      props: {
        mb: 1.15,
        lineHeight: '1.15',
        fontSize: {
          xs: '16px',
          sm: '18px'
        }
      }
    },
    ul: {
      component: Box,
      props: {
        component: 'ul',
        fontSize: {
          xs: '16px',
          sm: '18px'
        }
      }
    },
    li: {
      component: Box,
      props: {
        component: 'li',
        fontSize: {
          xs: '16px',
          sm: '18px'
        }
      }
    }
  },
  renderRule(next, node, renderChildren, state) {
    if (node.type === '15' && node.target.startsWith('/')) {
      return (
        <Link to={node.target}>{renderChildren(node.children, state)}</Link>
      );
    }

    return next();
  }
};

const pdfOptions: MarkdownToJSX.Options = {
  renderRule(next, node, renderChildren, state) {
    const heading: Style = {
      flexDirection: 'row',
      marginBottom: 12
    };
    const styleSheet = createStyleSheet({
      text: {},
      strong: {
        fontWeight: 700
      },
      h1: { ...heading, fontSize: 24, fontWeight: 900 },
      h2: { ...heading, fontSize: 22, fontWeight: 600 },
      h3: { ...heading, fontSize: 20, fontWeight: 500 },
      h4: { ...heading, fontSize: 16 },
      h5: { ...heading },
      h6: { ...heading },
      p: {
        flexDirection: 'row',
        marginBottom: 14
      },
      list: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 4
      },
      listItem: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 4,
        fontSize: 16
      },
      listItemIcon: {
        marginLeft: 20,
        marginRight: 8
      }
    });
    switch (node.type) {
      case '0':
        return (
          <Text style={styleSheet.text}>
            {renderChildren(node.children, state)}
          </Text>
        );

      case '9':
        return (
          <View style={styleSheet[`h${node.level}`]}>
            {renderChildren(node.children, {
              inline: true,
              simple: true
            })}
          </View>
        );

      case '27':
        return <Text style={styleSheet.text}>{node.text}</Text>;

      case '28':
        return (
          <Text style={styleSheet.strong}>
            {renderChildren(node.children, state)}
          </Text>
        );

      case '29':
      case '31':
      case '32':
        return (
          <Text style={styleSheet.text}>
            {renderChildren(node.children, state)}
          </Text>
        );

      case '21':
        return (
          <Text style={styleSheet.p}>
            {renderChildren(node.children, state)}
          </Text>
        );

      case '20':
      case '33':
        return (
          <View style={styleSheet.list}>
            {node.items.map((x, i) => {
              const nextChild = x.findIndex(
                (y) => !pdfTextRules.contains(y.type)
              );
              return (
                <>
                  <View style={styleSheet.listItem} key={`parent${i}`}>
                    <Text style={styleSheet.listItemIcon}>
                      {node.ordered ? `${i + 1}.` : '-'}
                    </Text>
                    <View key={`child${i}`}>
                      {renderChildren(x.slice(0, nextChild), state)}
                    </View>
                  </View>
                  <View
                    key={`child${i}`}
                    style={{
                      flexDirection: 'column'
                    }}
                  >
                    {renderChildren(x.slice(nextChild), state)}
                  </View>
                </>
              );
            })}
          </View>
        );

      default:
        break;
    }

    return next();
  }
};

const Markdown = ({ children, options }: IProps) => {
  if (children && children.startsWith('"') && children.endsWith('"')) {
    children = children.substring(1, children.length - 1);
  }

  if (children && children.startsWith('```') && children.endsWith('```')) {
    children = children.substring(3, children.length - 3);
  }

  return (
    <MarkdownToJsx options={{ ...defaultOptions, ...options }}>
      {children || ''}
    </MarkdownToJsx>
  );
};

export const PdfMarkdown = ({ children, options }: IProps) => {
  if (children && children.startsWith('"') && children.endsWith('"')) {
    children = children.substring(1, children.length - 1);
  }

  if (children && children.startsWith('```') && children.endsWith('```')) {
    children = children.substring(3, children.length - 3);
  }

  return (
    <MarkdownToJsx options={{ ...pdfOptions, ...options }}>
      {children || ''}
    </MarkdownToJsx>
  );
};

export default Markdown;
