import { Link, Text, View } from '@react-pdf/renderer';
import { A4Page, ChapterIcon, createStyleSheet } from '@survey/report/pdf';

interface IProps {
  items: TopicItemResponse[];
}

const DetailedContents = ({ items }: IProps) => {
  const styleSheet = createStyleSheet({
    wrapper: {
      display: 'flex',
      flexDirection: 'column'
    },
    link: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textDecoration: 'none',
      color: '#6D6E71'
    },
    iconWrapper: {
      marginRight: 16,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    number: {
      fontSize: 24,
      fontWeight: 'black',
      color: 'inherit',
      marginLeft: 12
    },
    name: {
      fontSize: 16,
      fontWeight: 'extralight',
      color: '#000'
    }
  });

  return (
    <A4Page>
      <View style={styleSheet.wrapper}>
        {items.map((x, i) => (
          <Link style={styleSheet.link} key={`detailedDataContents${i}`}>
            <View style={styleSheet.iconWrapper}>
              <ChapterIcon width={24} />
              <Text style={styleSheet.number}>{(i + 1).toFixedLength(2)}</Text>
            </View>
            <Text style={styleSheet.name}>{x.name}</Text>
          </Link>
        ))}
      </View>
    </A4Page>
  );
};

export default DetailedContents;
