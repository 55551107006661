import { useTranslation } from '@/components';
import {
  PdfTable,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow
} from '@/components/pdf-table';
import { fontWeights, getTextColor, lightenHexColor } from '@/helpers';
import { useTheme } from '@mui/material';
import { Text } from '@react-pdf/renderer';
import ItemProgress from './item-progress';

interface IProps {
  id: string;
  options: TopicOptionResponse[];
  rows: TableAnonymousItemResponse[];
  tableQuestionAverageDisabled?: boolean;
}

const TableAnonymous = ({
  id,
  options,
  rows,
  tableQuestionAverageDisabled
}: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');
  const hasBenchmark = rows.any((x) => !!x.benchmark && x.benchmark !== 0);

  const total = (rows: TableAnonymousItemResponse[]) => {
    return rows
      .filter((x) => !x.option.optional && !x.owner)
      .average((x) => x.option.value)
      .toFixedString(1, '-');
  };

  const totalBenchmark = (rows: TableAnonymousItemResponse[]) => {
    return rows
      .filter((x) => !x.option.optional && !x.owner)
      .average((x) => x.option.value)
      .toFixedString(1, '-');
  };

  return (
    <PdfTable>
      <TableHead>
        <TableCell isHeader empty />
        {options.map((y) => (
          <TableCell key={`item_${id}_option_${y.id}_title`} isHeader>
            {y.title}
          </TableCell>
        ))}
        <TableCell isHeader lastColumn={!hasBenchmark}>
          {t('Average')}
        </TableCell>
        {hasBenchmark && (
          <>
            <TableCell isHeader>{t('CohortBenchmark')}</TableCell>
            <TableCell isHeader lastColumn>
              {t('YoursVSOthers')}
            </TableCell>
          </>
        )}
      </TableHead>
      <TableBody>
        <TableRow rowIndex={0}>
          <TableCell isHeader firstColumn>
            {t('SelfRating')}
          </TableCell>
          {options.map((x) => (
            <TableCell center key={`item_${id}_self_option_${x.id}_value`}>
              {rows.any((y) => y.owner && y.option.value === x.value)
                ? 'Ok'
                : 'NA'}
            </TableCell>
          ))}
          <TableCell center lastColumn={!hasBenchmark}>
            {rows
              .filter((y) => y.owner && !y.option.optional)
              .average((y) => y.option.value!)
              .toFixedString(1, 'NA')}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell center>
                {rows
                  .filter((y) => y.owner)
                  .average((y) => y.benchmark || 0)
                  .toFixedString(1, 'NA')}
              </TableCell>
              <TableCell
                lastColumn
                style={{
                  padding: '4px 8px'
                }}
              >
                <ItemProgress
                  user={rows
                    .filter((y) => y.owner && !y.option.optional)
                    .average((y) => y.option.value!)}
                  other={rows
                    .filter((y) => y.owner)
                    .average((y) => y.benchmark || 0)}
                />
              </TableCell>
            </>
          )}
        </TableRow>
        <TableRow rowIndex={1} lastRow={tableQuestionAverageDisabled}>
          <TableCell isHeader firstColumn>
            {t('Others')}
          </TableCell>
          {options.map((x) => (
            <TableCell center key={`item_${id}_others_option_${x.id}_value`}>
              {rows.any((y) => !y.owner && y.option.value === x.value)
                ? 'Ok'
                : 'NA'}
            </TableCell>
          ))}
          <TableCell center lastColumn={!hasBenchmark}>
            {rows
              .filter((y) => !y.owner && !y.option.optional)
              .average((y) => y.option.value!)
              .toFixedString(1, 'NA')}
          </TableCell>
          {hasBenchmark && (
            <>
              <TableCell center>
                {rows
                  .filter((y) => !y.owner)
                  .average((y) => y.benchmark || 0)
                  .toFixedString(1, 'NA')}
              </TableCell>
              <TableCell
                lastColumn
                style={{
                  padding: '4px 8px'
                }}
              >
                <ItemProgress
                  user={rows
                    .filter((y) => !y.owner && !y.option.optional)
                    .average((y) => y.option.value!)}
                  other={rows
                    .filter((y) => !y.owner)
                    .average((y) => y.benchmark || 0)}
                />
              </TableCell>
            </>
          )}
        </TableRow>
      </TableBody>
      {!tableQuestionAverageDisabled && (
        <TableFooter>
          <TableCell firstColumn empty />
          <TableCell
            weight={options.length + 1}
            style={{
              textAlign: 'center',
              backgroundColor: lightenHexColor(
                theme.palette.secondary.main,
                80
              ),
              padding: 6,
              color: getTextColor(
                lightenHexColor(theme.palette.secondary.main, 80)
              )
            }}
          >
            <Text
              style={{
                fontWeight: fontWeights.semiBold
              }}
            >
              {t('QuestionAverage')}
            </Text>
            <Text
              style={{
                fontWeight: fontWeights.light,
                fontSize: 10
              }}
            >
              ({t('ExcludingSelfRating')})
            </Text>
          </TableCell>
          <TableCell lastColumn={!hasBenchmark}>{total(rows)}</TableCell>
          {hasBenchmark && (
            <>
              <TableCell>{totalBenchmark(rows)}</TableCell>
              <TableCell lastColumn />
            </>
          )}
        </TableFooter>
      )}
    </PdfTable>
  );
};

export default TableAnonymous;
