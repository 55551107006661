import { useTranslation } from '@/components';
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@/components/pdf-table';
import { ItemProgress, StyledTable } from '@survey/report/pdf';

interface IProps {
  id: string;
  rows: TableOverallItemResponse[];
  items: TopicItemResponse[];
}

const TableOverall = ({ id, rows, items }: IProps) => {
  const t = useTranslation('Reports.Table');
  const hasBenchmark = rows.any((x) => !!x.benchmark && x.benchmark !== 0);

  return (
    <StyledTable>
      <TableHead>
        <TableCell isHeader weight={hasBenchmark ? 10 : 11} empty firstColumn />
        <TableCell isHeader>{t('SelfRating')}</TableCell>
        <TableCell isHeader>{t('RatersRating')}</TableCell>
        {hasBenchmark && <TableCell isHeader>{t('CohortBenchmark')}</TableCell>}
        <TableCell isHeader weight={3} lastColumn>
          {t('YoursVSOthers')}
        </TableCell>
      </TableHead>
      <TableBody>
        {items.map((x, i) => {
          const data = rows.find((y) => y.itemId === x.id);
          return (
            <TableRow
              key={`table${id}_row${i}`}
              rowIndex={i}
              lastRow={i === items.length - 1}
            >
              <TableCell
                firstColumn
                weight={hasBenchmark ? 10 : 11}
                isHeader
                style={{
                  textAlign: 'left'
                }}
              >
                {x.name}
              </TableCell>
              <TableCell isHeader center>
                {data?.selfRating || 'NA'}
              </TableCell>
              <TableCell isHeader center>
                {data?.othersRating.toFixedString(1, 'NA')}
              </TableCell>
              {hasBenchmark && (
                <TableCell isHeader center>
                  {rows
                    .filter((x) => x.owner)
                    .average((x) => x.benchmark || 0)
                    .toFixedString(1, 'NA')}
                </TableCell>
              )}
              <TableCell weight={3} lastColumn>
                <ItemProgress
                  user={data?.selfRating || 0}
                  other={data?.othersRating || 0}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default TableOverall;
