import { Page, PageProps } from '@react-pdf/renderer';
import { PropsWithChildren } from 'react';

const A5Page = (props: PropsWithChildren<PageProps>) => {
  const defaultProps: PageProps = {
    size: 'A5',
    orientation: 'landscape'
  };

  const { style, ...otherProps } = props;

  return <Page {...defaultProps} style={style} {...otherProps} />;
};

export default A5Page;
