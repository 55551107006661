import { Text } from '@react-pdf/renderer';
import { ProgressBar } from '@survey/report/pdf';

const ItemProgress = (props: { user?: number; other?: number }) => (
  <>
    <Text
      style={{
        textAlign: 'center'
      }}
    >
      {props.user?.toFixedString(1, '-') || '-'} /{' '}
      {props.other?.toFixedString(1, '-') || '-'}
    </Text>
    <ProgressBar color='success' value={((props.user || 0) / 5) * 100} />
    <ProgressBar value={((props.other || 0) / 5) * 100} />
  </>
);

export default ItemProgress;
