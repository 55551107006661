import { useTranslation } from '@/components';
import { fontWeights, lightenHexColor } from '@/helpers';
import { useTheme } from '@mui/material';
import { Image, Rect, Svg, Text } from '@react-pdf/renderer';
import { A4Page, createStyleSheet } from '@survey/report/pdf';

interface IProps {
  title: string;
  isReport?: boolean;
  cover: string;
  icon?: string;
}

const SectionCoverBody = ({ title, isReport, cover, icon }: IProps) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');

  const styleSheet = createStyleSheet({
    page: {
      position: 'relative'
    },
    background: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0
    },
    cover: {
      position: 'absolute',
      width: '90%',
      left: '5%',
      top: 120
    },
    reportTitle: {
      position: 'absolute',
      fontSize: 24,
      left: '5%',
      top: 32,
      fontWeight: fontWeights.bold,
      color: lightenHexColor(theme.palette.primary.main, 50)
    },
    title: {
      position: 'absolute',
      fontSize: 16,
      left: '5%',
      top: 70,
      fontWeight: fontWeights.regular,
      color: '#000'
    },
    icon: {
      position: 'absolute',
      right: 0,
      width: '30%',
      top: '80%',
      transform: 'translateX(-50%)'
    }
  });

  return (
    <>
      <Svg viewBox='0 0 210 297' style={styleSheet.background}>
        <Rect
          x={80}
          width={130}
          height={297}
          fill={lightenHexColor(theme.palette.secondary.light, 60)}
        />
        <Rect
          x={0}
          y={150}
          height={60}
          width={80}
          fill={lightenHexColor(theme.palette.secondary.main, 50)}
        />
        {isReport && (
          <Rect fill='#DFD9E3' x={30} y={160} width={180} height={5} />
        )}
      </Svg>
      <Image src={cover} style={styleSheet.cover} />
      {isReport && (
        <Text style={styleSheet.reportTitle}>{t('360FeedbackReport')}</Text>
      )}
      <Text style={styleSheet.title}>{title}</Text>
      {icon && <Image style={styleSheet.icon} src={icon} />}
    </>
  );
};

const SectionCover = (props: IProps) => {
  const styleSheet = createStyleSheet({
    page: {
      position: 'relative'
    }
  });

  if (props.isReport) {
    return <SectionCoverBody {...props} />;
  }

  return (
    <A4Page style={styleSheet.page}>
      <SectionCoverBody {...props} />
    </A4Page>
  );
};

export default SectionCover;
