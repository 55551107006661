import SurveyRouter from '@test/survey';
import { Route, Routes } from 'react-router';

export default function TestRouter() {
  return (
    <Routes>
      <Route path='/survey/*' element={<SurveyRouter />} />
    </Routes>
  );
}
