import {
  A4Page,
  AIReport,
  ChapterCover,
  TableRenderer
} from '@survey/report/pdf';

type IProps = TopicItemResponse & {
  index?: string;
  cover?: string;
  tables?: TableResponseType[];
  aiReports?: SimpleAIReportResponse[];
  options: TopicOptionResponse[];
  relations?: TopicRelationResponse[];
  familiarity?: TopicFamiliarityResponse;
  section?: string;
  charts?: ChartResponse[];
  single?: boolean;
  tableQuestionAverageDisabled?: boolean;
};

const ReportItem = ({
  id,
  index,
  cover,
  name,
  tables,
  aiReports,
  options,
  section,
  relations,
  familiarity,
  charts,
  single,
  tableQuestionAverageDisabled
}: IProps) => {
  const aiReport = aiReports?.find((x) => x.itemId === id);

  return (
    <>
      <ChapterCover
        image={cover}
        index={index}
        name={name}
        section={section}
        single={single}
      />
      <A4Page>
        {tables &&
          tables.map((x) => (
            <TableRenderer
              key={`item_${id}`}
              id={`item_${id}`}
              table={x}
              options={options}
              relations={relations}
              familiarity={familiarity}
              tableQuestionAverageDisabled={tableQuestionAverageDisabled}
            />
          ))}
        {aiReport && (
          <AIReport
            title={single ? undefined : name}
            content={aiReport.content}
            charts={charts}
          />
        )}
      </A4Page>
    </>
  );
};

export default ReportItem;
