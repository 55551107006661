import { useTranslation } from '@/components';
import {
  languageChangeSubscribe,
  languageChangeUnsubscribe
} from '@/components/multi-lang';
import {
  ChartDataType,
  TableDataType,
  TopicItemTableType,
  TopicTableType
} from '@/enums';
import { fontWeights, getCdnUrl, lightenHexColor, randPerm } from '@/helpers';
import { ThemeProvider, useTheme } from '@mui/material';
import { Document, Font, Text, View } from '@react-pdf/renderer';
import {
  A4Page,
  AIReport,
  ChapterCover,
  CommentBox,
  Contents,
  createStyleSheet,
  DetailedContents,
  ReportItem,
  SectionCover,
  TableRenderer
} from '@survey/report/pdf';
import { useEffect } from 'react';

const SurveyReportPdf = ({
  aiReports,
  sections,
  items,
  relations,
  familiarity,
  options,
  commentSections,
  nickname,
  charts,
  summary,
  date,
  title,
  customizations,
  tables,
  sectionReports,
  comments,
  tableQuestionAverageDisabled
}: SimpleReportResponse) => {
  const theme = useTheme();
  const t = useTranslation('Reports.Table');

  const coverImage = (name: string) =>
    getCdnUrl(`/images/reports/chapters/${name}.jpg`);
  const commentImage = (index: number) =>
    getCdnUrl(`/images/reports/comments/${index % 4 || 4}.jpg`);

  const styleSheet = createStyleSheet({
    hero: {
      position: 'relative',
      height: '100%'
    },
    hero_background: {
      position: 'absolute',
      width: '100%',
      height: '100%'
    },
    hero_titlebar: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 24,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      padding: '6px 24px'
    },
    hero_nickname: {
      color: theme.palette.secondary.contrastText,
      fontWeight: fontWeights.semiBold,
      fontSize: 16
    },
    hero_nickname_separator: {
      color: theme.palette.secondary.contrastText,
      fontWeight: fontWeights.black,
      margin: '0 20px'
    },
    hero_nickname_date: {
      color: theme.palette.secondary.contrastText,
      fontWeight: fontWeights.light,
      fontSize: 12
    }
  });

  Font.register({
    family: 'Inter',
    fonts: [
      {
        src: `${process.env.REACT_APP_URL}/static/fonts/inter/inter-thin.ttf`,
        fontWeight: 100
      },
      {
        src: `${process.env.REACT_APP_URL}/static/fonts/inter/inter-extraLight.ttf`,
        fontWeight: 200
      },
      {
        src: `${process.env.REACT_APP_URL}/static/fonts/inter/inter-light.ttf`,
        fontWeight: 300
      },
      {
        src: `${process.env.REACT_APP_URL}/static/fonts/inter/inter-regular.ttf`,
        fontWeight: 400
      },
      {
        src: `${process.env.REACT_APP_URL}/static/fonts/inter/inter-medium.ttf`,
        fontWeight: 500
      },
      {
        src: `${process.env.REACT_APP_URL}/static/fonts/inter/inter-semiBold.ttf`,
        fontWeight: 600
      },
      {
        src: `${process.env.REACT_APP_URL}/static/fonts/inter/inter-bold.ttf`,
        fontWeight: 700
      },
      {
        src: `${process.env.REACT_APP_URL}/static/fonts/inter/inter-extraBold.ttf`,
        fontWeight: 800
      },
      {
        src: `${process.env.REACT_APP_URL}/static/fonts/inter/inter-black.ttf`,
        fontWeight: 900
      }
    ]
  });

  const summaryTables = tables
    .where((x) => x.dataType === TableDataType.SUMMARY)
    .flatMap((x) => x.tables);

  const sectionsTables = tables
    .where((x) => x.dataType === TableDataType.SECTION)
    .map((x) => ({
      id: x.dataId!,
      tables: tables
        .where(
          (y) => y.dataId === x.dataId && y.dataType === TableDataType.SECTION
        )
        .flatMap((y) => y.tables)
    }));

  const fixedComments = comments
    .flatMap((x) =>
      x.comments.map((y) => ({
        ...y,
        relationId: x.relationId
      }))
    )
    .groupBy((x) => x.commentId);

  tables = tables.map((a) => ({
    ...a,
    tables: a.tables.map((x) => {
      switch (x.type) {
        case TopicItemTableType.DETAILED:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            option: options.find((a) => a.id === z.optionId)!,
            relation: relations.find((a) => a.id === z.relationId)
          }));
          break;

        case TopicItemTableType.AVERAGE:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            relation:
              (z.relationId &&
                relations &&
                relations.find((a) => a.id === z.relationId)) ||
              undefined
          }));
          break;

        case TopicItemTableType.ANONYMOUS:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            option: options.find((a) => a.id === z.optionId)!
          }));
          break;

        case TopicItemTableType.FAMILIARITY:
          x.item = items.find((y) => y.id === x.itemId)!;
          x.items = x.items.map((z) => ({
            ...z,
            option: options.find((a) => a.id === z.optionId)!,
            familiarity:
              (z.familiarityId &&
                familiarity &&
                familiarity.options.find((a) => a.id === z.familiarityId)) ||
              undefined
          }));
          break;

        case TopicTableType.OVERALL:
        case TopicTableType.OVERALL_FAMILIARITY:
          x.item = items.find((y) => y.id === x.itemId)!;
          break;

        default:
          throw new Error('Type is not supported');
      }

      return x;
    })
  }));

  sectionReports = sectionReports?.map((x) => ({
    ...x,
    section: sections?.find((y) => y.id === x.sectionId)!
  }));

  comments = comments.map((x) => ({
    ...x,
    relation: relations.find((y) => y.id === x.relationId)
  }));

  const coverImages = randPerm(
    1,
    61,
    (sections?.length || 0) + items.length
  ).map((x) => coverImage(x.toString()));

  useEffect(() => {
    const languageSubscriptionId = languageChangeSubscribe((_, newLanguage) => {
      if (newLanguage === 'en') {
        document.location.reload();
      } else {
        document.location.href = `/${newLanguage}${document.location.pathAndSearch}`;
      }
    });

    return () => languageChangeUnsubscribe(languageSubscriptionId);
  }, []);

  return (
    <Document title={title} creator='GPT' producer='GPT'>
      <A4Page>
        <View style={styleSheet.hero_titlebar}>
          <Text style={styleSheet.hero_nickname}>
            {nickname}
            <Text style={styleSheet.hero_nickname_separator}>|</Text>
            {date && (
              <Text style={styleSheet.hero_nickname_date}>
                {date.asDateTime('MMMM yyyy')}
              </Text>
            )}
          </Text>
        </View>
        <View
          style={{
            position: 'absolute',
            left: 24,
            right: 24,
            top: 44,
            bottom: 0
          }}
        >
          <SectionCover
            cover={getCdnUrl('/images/reports/hero.png')}
            title={title}
            isReport
          />
        </View>
      </A4Page>
      {sectionReports ? (
        <Contents
          hasSummary={!!summary}
          items={sectionReports.map((x) => ({
            id: x.sectionId,
            name: x.section.title
          }))}
          isSections
        />
      ) : items.length > 1 ? (
        <Contents
          hasSummary={!!summary}
          hasComments={comments.length > 0}
          items={items}
        />
      ) : (
        <></>
      )}
      {summary && (
        <>
          <SectionCover
            title={t('ExecutiveSummary')}
            cover={getCdnUrl('/images/reports/executive-summary-h.jpg')}
          />
          <A4Page
            style={{
              padding: 8
            }}
          >
            {summaryTables &&
              summaryTables.map((x, i) => (
                <TableRenderer
                  key={`summary_${i}`}
                  id={`summary_${i}`}
                  table={x}
                  familiarity={familiarity}
                  items={items}
                  tableQuestionAverageDisabled={tableQuestionAverageDisabled}
                />
              ))}
            <AIReport
              content={summary}
              charts={charts
                ?.where((x) => x.dataType === ChartDataType.SUMMARY)
                .map((x) => x.chart)}
            />
          </A4Page>
        </>
      )}
      {sectionReports?.any()
        ? sectionReports?.map((x, i) => {
            const tables =
              sectionsTables &&
              sectionsTables.find((y) => y.id === x.sectionId)?.tables;
            return (
              <>
                <ChapterCover
                  key={`section_${x.sectionId}_cover`}
                  name={x.section.title}
                  image={coverImages[i]}
                  index={t('ChapterX', { x: i + (summary ? 2 : 1) })}
                />
                <A4Page
                  key={`section_${x.sectionId}`}
                  id={x.sectionId.replace(/[-_]/g, '')}
                  style={{
                    padding: 8
                  }}
                >
                  {tables &&
                    tables.map((y) => (
                      <TableRenderer
                        key={`section_${x.sectionId}`}
                        id={`section_${x.sectionId}`}
                        table={y}
                        familiarity={familiarity}
                        items={items.where((z) =>
                          x.section.itemIds.contains(z.id)
                        )}
                        tableQuestionAverageDisabled={
                          tableQuestionAverageDisabled
                        }
                      />
                    ))}
                  <AIReport
                    content={x.content}
                    title={x.section.title}
                    charts={charts
                      ?.where(
                        (y) =>
                          y.dataType === ChartDataType.SECTION &&
                          y.dataId === x.sectionId
                      )
                      .map((y) => y.chart)}
                  />
                </A4Page>
              </>
            );
          })
        : items.map((x, i) => (
            <ReportItem
              key={i}
              {...x}
              index={t('ChapterX', { x: i + (summary ? 2 : 1) })}
              options={options}
              relations={relations}
              tables={tables
                .filter(
                  (y) => y.dataType === TableDataType.ITEM && y.dataId === x.id
                )
                .flatMap((y) => y.tables)}
              aiReports={aiReports}
              cover={coverImages[i]}
              familiarity={familiarity}
              charts={charts
                ?.where(
                  (y) => y.dataType === ChartDataType.ITEM && y.dataId === x.id
                )
                .map((y) => y.chart)}
              single={items.length === 1}
              tableQuestionAverageDisabled={tableQuestionAverageDisabled}
            />
          ))}
      <ThemeProvider
        theme={{
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              ...theme.palette.primary,
              main: customizations?.primaryColor || '#31C7A0',
              light:
                (customizations?.primaryColor &&
                  lightenHexColor(customizations.primaryColor, 30)) ||
                '#EBFDF8'
            },
            secondary: {
              ...theme.palette.secondary,
              main: customizations?.secondaryColor || '#31C7A0',
              light:
                (customizations?.secondaryColor &&
                  lightenHexColor(customizations.secondaryColor, 30)) ||
                '#EBFDF8'
            }
          }
        }}
      >
        {sectionReports && sectionReports?.any() && (
          <>
            <SectionCover
              key='detailedData_Cover'
              title={t('DetailedData')}
              cover={getCdnUrl('/images/reports/detailed-data-h.jpg')}
            />
            <DetailedContents key='detailedData_Contents' items={items} />
            {items.map((x, i) => (
              <ReportItem
                key={i}
                {...x}
                index={`${i + 1}:`}
                options={options}
                relations={relations}
                tables={tables
                  .filter(
                    (y) =>
                      y.dataType === TableDataType.ITEM && y.dataId === x.id
                  )
                  .flatMap((y) => y.tables)}
                aiReports={aiReports}
                cover={coverImages[sectionReports!.length + i + 1]}
                section={t('DetailedData')}
                familiarity={familiarity}
                charts={charts
                  ?.where(
                    (y) =>
                      y.dataType === ChartDataType.ITEM && y.dataId === x.id
                  )
                  .map((y) => y.chart)}
                tableQuestionAverageDisabled={tableQuestionAverageDisabled}
              />
            ))}
          </>
        )}
        {commentSections &&
          commentSections.map((x, i) => {
            const items = fixedComments.find((y) => y.group === x.id);
            if (!items || items.items.length < 1) {
              return <></>;
            }

            return (
              <>
                <ChapterCover
                  key={`commentSection_${x.id}_Cover`}
                  name={x.title}
                  index={`${i + 1}:`}
                  image={commentImage(i + 1)}
                  section={t('Comments')}
                  // icon={<ChatBubble color='primary' fontSize='large' />}
                />
                <A4Page
                  key={`commentSection_${x.id}`}
                  id={`comments${i}`}
                  break={false}
                  style={{
                    padding: '24px 16px'
                  }}
                >
                  {items.items.map((x, i) => (
                    <CommentBox
                      key={i}
                      index={i}
                      {...x}
                      relation={relations.find((y) => y.id === x.relationId)}
                    />
                  ))}
                </A4Page>
              </>
            );
          })}
      </ThemeProvider>
    </Document>
  );
};

export default SurveyReportPdf;
